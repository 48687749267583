<template>
  <div class="mainBox">
    <About></About>
    <Job></Job>
    <Team></Team>
    <News></News>
    <Connect></Connect>
  </div>
</template>

<script>
import About from "@/components/About";
import Job from "@/components/Job";
import Team from "@/components/Team";
import News from "@/components/News";
import Connect from "@/components/Connect";

export default {
  name: "MainBox",
  components: {About, Job, Team, News, Connect},
}
</script>

<style scoped lang="less">
@media (max-width: 1024px) {
  //小于1024时
  .mainBox {
    //background-color: orange;
    //height: 100px;
    min-height: 50px;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  //大于1024时
  .mainBox {
    //background-color: grey;
    //height: 100px;
    min-height: 50px;
    width: 70%;
    margin: 0 auto;
  }
}
</style>
