<template>
  <div class="footer">
    Copyright@2016 Rogrand 京 ICP 备 07504877 号 -4
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="less">
.footer{
  background-color: lightgray;
  width: 100%;
  float: bottom;
  text-align: center;
  @media (max-width: 1024px) {//小于1024时
    height: 20px;
    line-height: 20px;
    font-size: 12px;
  }
  @media (min-width: 1024px) {//大于1024时
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}
</style>
