<template>
  <div class="connect">
    <div class="connectTitle">联系我们</div>
    <img class="p1" src="../assets/img/wangge.png" alt="">
    <div class="connectMain">
      <div class="left">
        <div class="childCompany">
          <div class="childCompanyNameBox">
            <div class="childCompanyName" v-for="(item,index) in childCompanyList" :key="index"
            :class="item.title===childCompanyActive.title?'childCompanyNameActive':''"
                 @click="changeChildCompany($event,index)"
            >{{item.title}}</div>
          </div>
          <div class="childCompanyInfo">
            <div class="childCompanyInfoBox">
              <div class="companyName">{{childCompanyActive.name}}</div>
              <div class="info">地址 : {{childCompanyActive.address}}</div>
              <div class="info">邮编 : {{childCompanyActive.code}}</div>
              <div class="phoneBox">
                <div class="info">电话 :</div>
                <div class="phoneContainer">
                  <div class="info" v-for="number in childCompanyActive.phone">{{number}}</div>
                </div>
              </div>
              <div class="info">邮箱 : {{childCompanyActive.email}}</div>
            </div>
            <div class="code">
              关注我们
              <div class="img" style=""></div>
            </div>
          </div>
        </div>
        <div class="describe">
24小时欢迎您的咨询，<br>请将您的问题通过页面进行提交，我们会在第一时间回复您！
        </div>
      </div>
      <div class="right">
        <div class="map">
          <baidu-map class='bm-view' @ready='init'></baidu-map>
        </div>
        <form  class="form">
          <div class="form1">
            <input type="text" placeholder="姓名">
            <input type="text" placeholder="邮箱">
            <input type="text" placeholder="电话">
          </div>
          <div class="form2">
<!--            您的问题-->
            <input type="text"  placeholder="您的问题">
          </div>
          <div class="form3">
            <button>提交</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Connect",
  data:function (){
    return {
      childCompanyList:[
        {
          title:"北京总公司",
          name:"北京融贯电子商务有限公司",
          address:"北京市朝阳区光华路4号东方梅地亚中心A座808",
          code:"100026",
          phone:["400-060-3351","010-85886877"],
          email:"rograndec@rograndec.com"
        },
        {
          title:"湖北分公司",
          name:"湖北分公司",
          address:"湖北省武汉市洪山区关山大道1号光谷软件园C6栋302-303室",
          code:"430073",
          phone:["027-50728233"],
          email:"amy.wu@rograndec.com"
        },
        {
          title:"上海分公司",
          name:"上海分公司",
          address:"上海市中山东二路600号外滩金融中心2幢2002室",
          code:"200030",
          phone:["15702150396"],
          email:"yingqi.jia@rograndec.com"
        },
        {
          title:"四川分公司",
          name:"四川分公司",
          address:"四川省成都市高新西区西芯大道3号国腾科技园11号楼A202室",
          code:"611730",
          phone:["028-87834001"],
          email:"peng.qiu@rograndec.com"
        },
        {
          title:"山东分公司",
          name:"山东分公司",
          address:"山东省济南市历城区华龙路1110号三威大厦701室",
          code:"250000",
          phone:["15588876655"],
          email:"yuanliang.wang@rograndec.com"
        },
        {
          title:"北区",
          name:"北区",
          address:"山西省太原市小店区经济开发区唐华街15号办公大楼307室",
          code:"030032",
          phone:["18603470905"],
          email:"jian.yang@rograndec.com"
        },
        {
          title:"东北区",
          name:"东北区",
          address:"辽宁省沈阳市铁西区北三中路24甲3号4-6-3",
          code:"110026",
          phone:["18602468611"],
          email:"yuejuan.li@rograndec.com"
        },
        {
          title:"南1区",
          name:"南1区",
          address:"广州市海珠区昌岗中路合生帝景国际D栋1606室",
          code:"510000",
          phone:["18911997186"],
          email:"mingqing.tian@rograndec.com"
        },
        {
          title:"南2区",
          name:"南2区",
          address:"湖南省长沙市雨花区金海路城南时代20楼融贯电商",
          code:"400007",
          phone:["18831985199"],
          email:"liguo.liu@rograndec.com"
        },
      ],
      childCompanyActive:{}
    }
  },
  methods:{
    changeChildCompany(event,index){
      this.childCompanyNameActive=this.childCompanyList[index].title
      this.childCompanyActive=this.childCompanyList[index]
    },
    init({BMap, map}){
      // this.map = map
      // var point = new BMap.Point(108.910752, 34.237372)  //设置城市经纬度坐标
      // map.centerAndZoom(point, 15)    //
      // map.setCurrentCity('西安')   // 设置地图显示的城市 此项是必须设置的
      // map.enableScrollWheelZoom(true)    //鼠标滚动缩放
      //
      // // #for(var i in mapdata) {
      // // #    var pt = new BMap.Point(mapdata[i].longitude, mapdata[i].latitude )   //经纬度
      // // #    var myicon = new BMap.Icon('图片路径', new BMap.Size(32, 32))   //设置对应图片和图片大小
      // // #    var marker2 = new BMap.Marker(pt, { icon: myicon })  //创建标注
      // // #    map.addOverLay(marker2)
      // // #}
      this.map = map
      var point = new BMap.Point(116.47966,39.919034)  //设置城市经纬度坐标
      //var cityCtrl = new BMap.CityListControl()    //城市下来框选择定位
      // map.addControl(cityCtrl)
      // var locationCtrl = new BMap.LocationControl()
      map.centerAndZoom(point, 4)    //
      map.setCurrentCity('北京融贯电子商务有限公司')   // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true)    //鼠标滚动缩放
      // map.setHeading(60) //设置地图旋转角度
      // map.setTilt(73)   //设置地图倾斜角度
     // map.setMapStyle({styleJson: CUSTOM_MAP_CONFIG})    //导入主题样式

      var data_info = [
        [114.409952,30.481725,"地址：湖北省武汉市洪山区关山大道1号光谷软件园C6栋302-303室<h4>湖北分公司</h4>"],
        [121.504425,31.232106,"地址：上海市中山东二路600号外滩金融中心2幢2002室<h4>上海分公司</h4>"],
        [103.97712,30.736615,"地址：四川省成都市高新西区西芯大道3号国腾科技园11号楼A202室 <h4>四川分公司</h4>"],
        [117.089702,36.686442,"地址：山东省济南市历城区华龙路1110号三威大厦701室<h4>山东分公司</h4>"],
        [112.599536,37.7376,"地址：山西省太原市小店区经济开发区唐华街15号办公大楼307室 <h4>北区</h4>"],
        [123.361877,41.817653,"地址：辽宁省沈阳市铁西区北三中路24甲3号4-6-3 <h4>东北区</h4>"],
        [113.282223,23.095094,"地址：广州市海珠区昌岗中路合生帝景国际D栋1606室 <h4>南1区</h4>"],
        [113.030198,28.096007,"地址：湖南省长沙市雨花区金海路城南时代20楼融贯电商 <h4>南2区</h4>"],
      ];
      var opts = {
        min_width : 250,     // 信息窗口zuixiao宽度
        min_height: 80,     // 信息窗口高度
        title : "详细信息" , // 信息窗口标题
        enableMessage:true//设置允许信息窗发送短息
      };
      for(var i=0;i<data_info.length;i++){
        var marker = new BMap.Marker(new BMap.Point(data_info[i][0],data_info[i][1]));  // 创建标注
        var content = data_info[i][2];
        map.addOverlay(marker);               // 将标注添加到地图中
        addClickHandler(content,marker);
      }
      function addClickHandler(content,marker){
        marker.addEventListener("click",function(e){
          openInfo(content,e)}
        );
      }
      function openInfo(content,e){
        var p = e.target;
        var point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
        var infoWindow = new BMap.InfoWindow(content,opts);  // 创建信息窗口对象
        map.openInfoWindow(infoWindow,point); //开启信息窗口
      }

      //向地图中添加缩放控件
      // var ctrl_nav = new BMap.NavigationControl({anchor:CUSTOM_MAP_CONFIG,type:CUSTOM_MAP_CONFIG});
      var ctrl_nav = new BMap.NavigationControl();
      map.addControl(ctrl_nav);
      //向地图中添加缩略图控件
      // var ctrl_ove = new BMap.OverviewMapControl({anchor:CUSTOM_MAP_CONFIG,isOpen:1});
      var ctrl_ove = new BMap.OverviewMapControl();
      map.addControl(ctrl_ove);
      //向地图中添加比例尺控件
      // var ctrl_sca = new BMap.ScaleControl({anchor:CUSTOM_MAP_CONFIG});
      var ctrl_sca = new BMap.ScaleControl();
      map.addControl(ctrl_sca);

      // map.addEventListener('click',function(e){
      //     console.log(e);
      // alert('经度：' + e.point.lng + '\n纬度：' + e.point.lat)
      // window.location.href = 'http://114.116.211.121:49151'

    }
  },
  mounted() {
    this.changeChildCompany("",0)
    //this.init()
  }
}
</script>

<style scoped lang="less">
.connect{
  //background-color: lemonchiffon;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .connectTitle{
    //background-color: lawngreen;
    font-weight: bold;
  }
  .connectMain{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    //background-color: lightblue;
    .left{
      //background-color: lightcoral;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .childCompany{
        //background-color: lightsteelblue;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .childCompanyNameBox{
          height: 100%;
          //background-color: lightcyan;
          .childCompanyName{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 10% ;
            width: 100%;
            cursor: pointer;
          }
          .childCompanyNameActive{
            border-bottom: 2px solid orange;
            color: orange;
            font-weight: bold;
          }
        }
        .childCompanyInfo{
          height: 100%;
          //background-color: lightgrey;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: start;
          text-align: left;
          .childCompanyInfoBox{
            .companyName{
            }
            .phoneBox{
              display: flex;
              flex-direction: row;
              justify-content: start;
              align-items: start;
              .phoneContainer{
                margin-left: 4px;
              }
            }
          }
          .code{

          }
        }
      }
      .describe{
        //background-color: lavenderblush;
      }
    }
    .right{
      //background-color: lightgray;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .map{
        width: 100%;
        background-color: lightskyblue;
        .bm-view { width: 100%; height: 100%; }
      }
      .form{

      }
    }
  }
  @media (max-width: 1024px) {//小于1024时
    .connectTitle{
      margin-bottom: 10px  ;
      font-size: 18px ;
      letter-spacing: 3px;/*字间距*/
    }
    .p1{
      margin-bottom: 10px ;
    }
    .connectMain{
      height: 200px;
      width: 100%;
    }
    .left{
      height: 100%;
      width: 63%;
      .childCompany{
        height: 100%;
        .childCompanyNameBox{
          width: 40%;
          font-size: 12px;
          .childCompanyName{
            margin-bottom: 2px;
          }
        }
        .childCompanyInfo{
          width: 60%;
          .childCompanyInfoBox{
            font-size: 10px;
            .companyName{
              margin-top: 2px;
            }
          }
          .code{
            display: none;
          }
        }
      }
      .describe{
        display: none;
        //height: 20%;
        ////line-height: 10px;
        //font-size: 12px;
        //text-align: left;
        //overflow: auto;
      }
    }
    .right{
      display: none;
      height: 100%;
      width: 35%;
      .map{
        height: 100%;
      }
      .form{
        display: none;
      }
    }
  }
  @media (min-width: 1024px) {//大于1024时
    .connectTitle{
      margin-bottom: 20px  ;
      font-size: 25px ;
      letter-spacing: 3px;/*字间距*/
    }
    .p1{
      margin-bottom: 20px ;
    }
    .connectMain{
      height: 600px;
      width: 100%;
      .left{
        height: 100%;
        width: 48%;
        .childCompany{
          height: 80%;
          width: 100%;
          .childCompanyNameBox{
            width:20%;
          }
          .childCompanyInfo{
            width: 80%;
            font-size: 18px;
            .childCompanyInfoBox{
              .companyName{
                margin-top: 12px;
                //font-size: 18px;
                margin-bottom: 25px;
              }
              .info{
                line-height: 25px;
                //height: 25px;
                padding-bottom: 10px;
              }
            }
            .code{
              line-height: 42px;
              .img {
                height: 200px;
                width: 200px;
                background-image: url('../assets/img/img_1.png');
                // 背景图片是否重复
                background-repeat: no-repeat;
                // 背景图片大小
                background-size: cover;
                // 背景颜色
                background-color: #000;
                // 背景图片位置
                background-position: center top
              }
            }
          }
        }
        .describe{
          height: 20%;
          width: 100%;
          line-height:40px;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          //padding-top: 20px;
        }
      }
      .right{
        height: 100%;
        width: 48%;
        .map{
          height: 80%;

        }
        .form{
          height: 19%;
          width: 100%;
          //background-color: lawngreen;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: start;
          .form1{
            //background-color: lightseagreen;
            width: 97%;
            height: 32%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: start;
            input{
              //background-color: red;
              width: 32%;
            }
          }
          input{
            border-bottom: black 1px solid;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            height: 100%;
            line-height: 100%;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            font-size: 14px;
          }
          input:hover{
            border-bottom: orange solid 1px;
          };
          .form2{
            width: 97%;
            height: 32%;
            background-color: limegreen;
            input{
              width: 100%;
              height: 100%;
              //background-color: slateblue;
            }
          }
          .form3{
            width: 100%;
            height: 32%;
            //background-color: lightcoral;
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: center;
            button{
              width: 90px;
              height: 30px;
              background-color: black;
              color: white;
              font-size: 15px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
