<template>
  <div class="team">
    <div class="teamTitle">创新大健康产业联盟</div>
    <img class="p1" src="../assets/img/wangge.png" alt="">
    <div class="teamMain">
      <img src="../assets/img/video_mask.jpg" alt="">
      <div class="teamDescribe">
        <div class="teamDescribeTitle">基于全产业链的大健康产业联盟</div>
        <div class="teamDescribeContainer">
          积极探索整合传统医药、医疗与互联网的创新发展联盟；<br>
          <br>
          由行业协会、资深专家、医药行业企业、科研机构、金融机构等自愿组成的社会团队，致力于打造营销创新、服务创新与生态创新的生态圈；
        </div>
        <div class="more">MORE +</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team",
}
</script>

<style scoped lang="less">
.team{
  background-color: lavender;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .teamTitle{
    //background-color: lawngreen;
    font-weight: bold;
  }
  .teamMain{
    background-color: lavenderblush;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    img{
      cursor: pointer;
    }
    .teamDescribe{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: start;
      .teamDescribeTitle{
        font-weight: bold;
        text-align: left;
      }
      .teamDescribeContainer{
        text-align: left;
        color: grey;
      }
      .more{
        background-color: black;
        color: white;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 1024px){//小于1024时
    .teamTitle{
      margin-bottom: 10px  ;
      font-size: 18px ;
      letter-spacing: 3px;/*字间距*/
    }
    .p1{
      margin-bottom: 10px ;
    }
    .teamMain{
      //height: 400px;
      width: 100%;
      //overflow: hidden;
      img{
        height: 100%;
        width: 35%;
        position: relative;
        top:-30%
      }
      .teamDescribe{
        width: 60%;
        height: 100%;
        //display: flex;
        //flex-direction: column;
        //justify-content: space-around;
        .teamDescribeTitle {
          font-size: 14px;
        }
        .teamDescribeContainer{
          display: none;
          //font-size: 12px;
        }
        .more{
          width: 80px;
          height: 35px;
          line-height: 35px;
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }
  @media (min-width: 1024px){//大于1024时
    .teamTitle{
      margin-bottom: 20px  ;
      font-size: 25px ;
      letter-spacing: 3px;/*字间距*/
    }
    .p1{
      margin-bottom: 20px ;
    }
    .teamMain{
      height: 400px;
      img{
        height: 100%;
        width: 40%;
      }
      .teamDescribe{
        width: 50%;
        height: 100%;
        letter-spacing: 2px;/*字间距*/
        .teamDescribeTitle{
          font-size: 18px;
        }
        .teamDescribeContainer{
          font-size: 16px;
        }
        .more{
          width: 130px;
          height: 40px;
          line-height: 40px ;
        }
      }

    }
  }
}
</style>
