<template>
  <div>
    <Banner></Banner>
    <Main></Main>
    <Footer></Footer>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import Main from "@/components/Main";
import Footer from "@/components/Footer";
export default {
  name: "Index",
  components:{Banner,Main,Footer},
}
</script>

<style scoped>

</style>
