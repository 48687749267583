<template>
  <div class="main" >
    <a name="mao"></a>
    <Nav></Nav>
    <MainBox></MainBox>
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import MainBox from "@/components/MainBox";
export default {
  name: "Main",
  components:{Nav,MainBox},
}
</script>

<style scoped lang="less">
.main{
  background-color: white;
}
</style>
