<template>
  <div class="nav">
    <img src="../assets/img/logo2.png" alt="">
    <div class="navBox">
      <NavItem title="首页"></NavItem>
      <div class="navBoxItem2" @click:stop="test()" @mousemove="showSecondNav(true)" @mouseleave="showSecondNav(false)">关于融贯
        <div class="secondNavBox" v-if="showSecondNavSign">
          <div class="secondNav" @click:stop="test()">企业简介</div>
          <div class="secondNav" @click:stop="test()">企业荣耀</div>
        </div>
      </div>
      <NavItem title="业务体系"></NavItem>
      <NavItem title="产业联盟"></NavItem>
      <NavItem title="新闻中心"></NavItem>
      <NavItem title="企业OA"></NavItem>
      <NavItem title="联系我们"></NavItem>
    </div>
  </div>
</template>

<script>
import NavItem from "@/components/NavItem";
export default {
  name: "Nav",
  components:{NavItem},
  data(){
    return{
      showSecondNavSign:false,
    }
  },
  methods:{
    showSecondNav(sign){
      this.showSecondNavSign=sign
    },
    test(){
      console.log(222)
    }
  }
}
</script>

<style scoped lang="less">

@media  (max-width: 1024px){//小于1024时
  .nav{
    //background-color: pink;
    //height: 100px;
    height: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    img{
      width: 10%;
    }
    .navBox{
      //background-color: palegoldenrod;
      height: 100%;
      width: 90%;
      font-size: 8px;
      .navBoxItem2{
        .secondNavBox{
          position: absolute;
          top: 106%;
          width: 110%;
          height: 100%;
          .secondNav{
            width: 100%;
            height: 100%;
            font-size: 12px;
          }
        }
      }
      .navBoxItem2:hover{
        border-bottom: 2px solid orange;
        background-color: lightblue;
      }
    }
  }
}
@media  (min-width: 1024px){//大于1024时
  .nav{
    //background-color: red;
    //height: 100px;
    height: 50px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    img{
      width: 10%;
    }
    .navBox{
      //background-color: palegoldenrod;
      height: 100%;
      width: 80%;
      .navBoxItem2{
        .secondNavBox{
          position: absolute;
          top: 106%;
          width: 100%;
          height: 100%;
          //background-color: red;
          .secondNav{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.navBox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .navBoxItem2 {
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    position: relative;
    .secondNav{
      border-bottom: 1px solid lightsteelblue;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
    .secondNav:hover{
      border-bottom: 2px solid orange;
    }
  }
  .navBoxItem2:hover{
    border-bottom: 2px solid orange;
    background-color: lightblue;
  }
}


</style>
