<template>
  <div class="navItem" @click.stop="clickA()">{{ title }}</div>
</template>

<script>
// import $ from 'jQuery'
export default {
  name: "NavItem",
  props:{
    title:{
      type:String,
      default:""
    }
  },
  methods:{
    clickA(){
      if (this.title==="首页"){
        location.reload()
        console.log(12)
      }
    },
  }
}
</script>

<style scoped lang="less">
.navItem{
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow:1;
}
.navItem:hover{
  border-bottom: 2px solid orange;
  background-color: lightblue;
}
</style>
