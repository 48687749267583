<template>
  <div class="about">
    <div class="title">关于我们</div>
    <img class="p1" src="../assets/img/wangge.png" alt="">
    <div class="main">
      <div class="left">
        <div class="companyTitle">北京融贯电子商务有限公司</div>
        <div class="spliteLine"></div>
        <div class="container">
          <p>
            以汇聚产业力量、守护生命健康为己任，北京融贯电子商务有限公司成立于2012年，是中国目前颇具规模的医药全产业链整合服务平台、提供医药电子商务综合性服务的专业公司，业务覆盖30个省份、500余家商业公司、150000余家终端药店、非公立医疗机构及基层诊所实现全国覆盖。</p>
          <p>
            公司技术力量雄厚，研发人员超过百人，凭借医药互联网应用方面深厚的技术底蕴和丰富的平台研发、整体规划与服务创新能力，始终致力于为医药生产企业、医药商业企业、终端药店及诊所进行“互联网+”转型升级服务，助力医药及大健康产业的互联网加进程，提升药品行业供应链效率及营销效率，打造出基于“B2B+SAAS”的智能生态服务体系，构建了服务医药全产业链的“S2B2C”新模式。</p>


        </div>
      </div>
      <div class="right">
        <img src="../assets/img/about.jpg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped lang="less">
.about{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //background-color: lavenderblush;
  padding-top: 10px;
  @media (max-width: 1024px){//小于1024时
    .title{
      font-size: 16px;
      letter-spacing: 3px;/*字间距*/
    }
    .main{
      height: 128px;
      .left{
        .companyTitle{
          font-size: 14px;
        }
        .spliteLine{
          width: 10%;
          height: 2%;
        }
        .container{
          height: 70%;
          width: 100%;
          font-size: 12px;
          overflow-y: auto;
          overflow-x: hidden;
          p{
            margin: 0;
          }
        }
      }
      .right{
        img{
          width: 80%;
        }
      }
    }
  }
  @media (min-width: 1024px){//大于1024时
    .title{
      font-size: 25px;
      letter-spacing: 3px;/*字间距*/
    }
    .main{
      height: 360px;
      .left{
        .companyTitle{
          height: 10%;
          width: 100%;
          font-size: 23px ;
        }
        .spliteLine{
          height: 1%;
          width: 10%;

        }
        .container{
          height: 80%;
          width: 100%;
          font-size: 16px;
        }
      }
    }
  }
  .title{
    font-weight: bold;
    padding-bottom: 10px;
  }
  .p1{
    margin-bottom: 10px;
  }
  .main{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .left{
      width: 55%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: start;
      .companyTitle{
        //background-color: lavender;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        font-weight: bold;
      }
      .spliteLine{
        background-color: orange;
      }
      .container{
        //background-color: lightcyan;
        text-align: left;
        //display: flex;
        //flex-direction: column;
        //justify-content: space-around;
        //align-items: start;
      }
    }
    .right{
      width: 40%;
      height: 100%;
      //display: flex;
      //flex-direction: column;
      //justify-content: space-around;
      //align-items: end;
      text-align: right;
    }
  }
}

</style>
