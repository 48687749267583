<template>
  <div class="job">
    <div class="jobTitle">业务体系</div>
    <img class="p1" src="../assets/img/wangge.png" alt="">
    <div class="jobMain">
      <div class="jobItem">
        <img class="jobItemImg" src="../assets/img/index_business1.jpg" alt="">
        <div class="jobItemContiner">
          <img src="../assets/img/business_logo1.png" alt="">
          <div class="jobItemDescribe">
            <div class="jobItemTitle">我的医药网</div>
            <span>医药供应链第三方交易平台</span>
            <span>规模较大行业占有率较大</span>
          </div>
          <div class="go"></div>
        </div>
      </div>
      <div class="jobItem">
        <img class="jobItemImg" src="../assets/img/index_business2.jpg" alt="">
        <div class="jobItemContiner">
          <img src="../assets/img/business_logo2.png" alt="">
          <div class="jobItemDescribe">
            <div class="jobItemTitle">菲加云</div>
            <span>Saas云药房智慧解决方案平台</span>
            <span>生态赋能助力中国医药零售升级</span>
          </div>
          <div class="go"></div>
        </div>
      </div>
      <div class="jobItem">
        <img class="jobItemImg" src="../assets/img/index_business3.jpg" alt="">
        <div class="jobItemContiner">
          <img src="../assets/img/business_logo3.png" alt="">
          <div class="jobItemDescribe">
            <div class="jobItemTitle">我的诊所</div>
            <span>医药供应链第三方交易平台</span>
            <span>规模较大行业占有率较大</span>
          </div>
          <div class="go"></div>
        </div>
      </div>
      <div class="jobItem">
        <img class="jobItemImg" src="../assets/img/index_business4.jpg" alt="">
        <div class="jobItemContiner">
          <img src="../assets/img/business_logo4.png" alt="">
          <div class="jobItemDescribe">
            <div class="jobItemTitle">我的康康</div>
            <span>终端本地化O2O医药健康服务平台</span>
            <span>服务消费者购药/咨询/健康管理一体化</span>
          </div>
          <div class="go"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Job"
}
</script>

<style scoped lang="less">
.job{
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: url('../assets/img/business.jpg');
  .jobTitle{
    color: white;
    font-weight: bold;
    letter-spacing: 3px;/*字间距*/
  }
  .jobMain{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .jobItem{
      width: 20%;
      height: 100%;
      background-color: lightcyan;
      .jobItemImg{
        width: 100%;
      }
      .jobItemContiner{
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .jobItemDescribe{
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .jobItemTitle{
            font-size: 18px;
            font-weight: bold;
          }
        }
        .go{

        }
      }
      .jobItemContiner:hover{
        transform: translateY(-15%);
      }
    }
  }
  @media (max-width: 1024px) {  //小于1024时
    .jobTitle{
      font-size: 18px;
      color: white;
      margin-bottom: 10px;
    }
    .p1{
      margin-bottom: 10px;
    }
    .jobMain{
      height: 130px;
      //background-color: red;
      padding-top: 10px;
      .jobItem{
        overflow: hidden;
        .jobItemImg{
          //width: 100%;
          height: 80px;
        }
        .jobItemContiner{
          height: 70px;
          img{
            width: 80%;
          }
          .jobItemDescribe{
            display: none;
          }
        }
      }
    }
  }
  @media (min-width: 1024px) {  //大于1024时
    .jobTitle{
      font-size: 26px;
      color: white;
      margin-bottom: 10px;
    }
    .p1{
      margin-bottom: 10px;
    }
    .jobMain{
      height: 400px;
      //background-color: deeppink;
      padding-top: 20px;
      .jobItem{
        width: 100%;
        overflow: hidden;
        .jobItemImg{
          height: 250px;
        }
        .jobItemContiner{
          height: 220px;
        }
      }
    }
  }

}
</style>
