import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Index from "@/views/Index";

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: Index,
    // meta:{title:"我是首页"},
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
  if(to.meta.title){//判断是否有标题
    document.title = to.meta.title
  }else{
    document.title ="融贯电商——中国医药全产业链整合服务平台"
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})
export default router
