<template>
  <div class="news">
    <div class="newsTitle">新闻中心</div>
    <img class="p1" src="../assets/img/wangge.png" alt="">
    <div class="more">MORE +</div>
  </div>
</template>

<script>
export default {
  name: "News"
}
</script>

<style scoped lang="less">
.news{
  background-color: #EEEEEE;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .newsTitle{
    //background-color: lawngreen;
    font-weight: bold;
  }
  .more{
    background-color: black;
    color: white;
    font-weight: bold;
    cursor: pointer;
    letter-spacing: 2px;/*字间距*/
  }
  @media (max-width: 1024px) {//小于1024时
    .newsTitle{
      margin-bottom: 10px  ;
      font-size: 18px ;
      letter-spacing: 3px;/*字间距*/
    }
    .p1{
      margin-bottom: 10px ;
    }
    .more{
      width: 80px;
      height: 35px;
      line-height: 35px;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 10px;;
    }
  }
  @media (min-width: 1024px) {//大于1024时
    .newsTitle{
      margin-bottom: 20px  ;
      font-size: 25px ;
      letter-spacing: 3px;/*字间距*/
    }
    .p1{
      margin-bottom: 20px ;
    }
    .more{
      width: 130px;
      height: 40px;
      line-height: 40px ;
      font-size: 16px ;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
