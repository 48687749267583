<template>
  <div class="banner">
    <video autoplay loop muted src="../assets/video/banner_video.mp4" @resize="videoResize($event)" @canplay="canplay($event)"></video>
    <div class="logoContainer">
      <img src="../assets/img/logo3.png" alt="">
      <div class="spliteLine"></div>
      <div class="describe">中国医药全产业链整合服务平台</div>
      <span>The China's pharmaceutical entire integration of industry chain service platform.</span>
      <a href="#mao">
        <div class="carousel-scroll">
          <em>
            <div class="c1"></div>
            <div class="c2"></div>
          </em>
          <span>Scroll down to see more</span>
        </div>
      </a>

    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: "Banner",
  data:function (){
    return {
      number:0
    }
  },
  mounted() {
    this.videoResize()
  },
  methods:{
    videoResize(){
      let videoHeight=$(".banner video:first").height()*(1-0.26)
      let carouselScrollObj=$(".banner .carousel-scroll:first")
      let p,h,s,w
      videoHeight<1024?p=0.05:p=0.2
      videoHeight<1024?h=0.4:h=0.5
      videoHeight<1024?s=0.26:s=0.07
      videoHeight<1024?w=1:w=0.4
      carouselScrollObj.height(videoHeight*s)
      carouselScrollObj.width(videoHeight*w)
      $(".logoContainer").css('height',videoHeight)
      let weiyi=videoHeight-(videoHeight*p+carouselScrollObj.height())
      let emH=carouselScrollObj.height()*h
      carouselScrollObj.css("top", weiyi)
      $(".banner em:first").css("height",emH)

      $(".main:first").css("top",-videoHeight*0.3955)

    },
  },
  destroyed() {
    localStorage.setItem("video","2")
  }
}
</script>

<style scoped lang="less">
.banner {
  overflow: hidden;
      //height: 100vh;
  //overflow-x: hidden;
  width: 100%;
  position: relative;
  video {
    width: 100%;
    //transform: translateY(-14%);//这个14,是视频自身黑幕的高度
  }
  .logoContainer{
    position: absolute;
    top: 0;
    //height: 100%;
    width: 100%;
    //background-color: green;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    align-items: center;

    overflow: hidden;
    img{
      //background-color: red;
      width: 20%;
    }
    .spliteLine{

      background-color: #F8B500;
    }
    .describe{
      margin-top: 1vw;
      height: 5vw;
      line-height: 5vw;
      font-weight: bold;
      font-size: 3vw;
      color: white;
      letter-spacing: 3px;/*字间距*/
    }
    span{
      color: white;
      width: 100vw;
      height: 2%;
      line-height: 2%;
      margin-top: 1vh;
      font-size: 1vw;
      white-space: pre;/*不换行*/
    }
    a{
      text-decoration:none;
      .carousel-scroll{

        //background: red;
        //position: absolute;

        width: 20%;
        min-width: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        em{/*椭圆*/

          border: 2px solid #fff;
          border-radius: 15px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          //justify-content: space-around;
          align-items: center;
          .c1{
            width: 10%;
            height: 40%;
            background: white;
          }
          .c2{
            width: 5px;
            height: 5px;
            border-right: 2px solid white;
            border-bottom: 2px solid white;
            transform: translateX(-5%) rotate(45deg);
          }
        }
        span{
          color: white;
          margin: 0;
        }
      }
    }

  }
  @media (max-width: 1024px) {  //小于1024时
    .logoContainer{
      padding-top: 10%;
      .spliteLine{
        margin-top: 3%;
        width: 10%;
        height: 3%;
      }
      a{
        .carousel-scroll{
          margin-top: 1vh;
          em{
            width: 6%;
            //height: 300%;
          }
        }
      }
    }
  }
  @media (min-width: 1024px) {  //大于1024时
    .logoContainer{
      padding-top: 15%;
      .spliteLine{
        margin-top: 1%;

      }
    }
    a{
      .carousel-scroll{
        margin-top: 5vh;
        em{
          width:5%;
          height: 50%;
        }
      }
    }

  }
}

</style>
